import React, { useLayoutEffect, useRef, useState } from "react";
import Banner from "./Includes/Banner";
import Navbar from "./Includes/Navbar";
import "./EmployeeTable.css";
import RsaService from "./Services/rsa.service";
import WebsitesService from "./Services/websites.service";
import StoreService from "./Services/store.service";
import AlertInfo from "./Includes/AlertInfo";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Select2Field from "./Includes/Select2Field";

export default function StoreTable() {
  const [loading, setLoading] = useState(false);
  const [websites, setWebsites] = useState([]);

  const [resultData, setResultData] = useState([]);
  const [selectedWebsite, setSelectedWebsite] = useState("");

  let cmpName = "List Store";

  const changeWebsite = (e) => {
    let value = e.target.value;

    setSelectedWebsite(value);
    if (value !== "") {
      getData(value);
      localStorage.setItem("site", value);
    } else {
      setResultData([]);
    }
  };

  const getWebsites = async () => {
    setLoading(true);
    try {
      const response = await WebsitesService.getWebsitesList();

      console.log("res=" + JSON.stringify(response.data.response));
      setWebsites(response.data.response);
      setLoading(false);
    } catch (err) {
      if (err?.response?.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const getData = async (value) => {
    // console.log(selectedWebsite);
    setLoading(true);
    try {
      const response = await StoreService.getStoreList(value);

      let resultData;
      resultData = response.data.response;
      setResultData(resultData);
      setLoading(false);
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  useLayoutEffect(() => {
    getWebsites();
    // getData();
  }, []);

  const alertDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are deleting store!",
      icon: "warning",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#DD6B55",
      showCancelButton: true,
      focusConfirm: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteStore(id, selectedWebsite);
      }
    });
  };

  const deleteStore = async (id, url) => {
    try {
      setLoading(true);
      await StoreService.deleteStore(id, url);

      getData(selectedWebsite);

      setLoading(false);
      Swal.fire({
        title: "Success",
        text: "Store has been deleted successfully",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#28a745",
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "Error in deleting Store",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#28a745",
      });
    }
  };
  return (
    <>
      <Navbar />
      <Banner cmpName={cmpName} />
      <div className="container">
        <div className="row">
          <AlertInfo />
          {/* <div className="mt-3">
            <label className="title">Website</label>
            <select
              ref={selectedWebsite}
              name="fieldtype"
              required
              onChange={(e) => changeWebsite(e)}
            >
              <option value="">Select</option>
              {websites.map((res) => {
                return (
                  <option key={res.id} value={res.url}>
                    {res.name}
                  </option>
                );
              })}
            </select>
          </div> */}
          <div className="col-lg-3 p-0 pt-3">
            <label className="title">Website</label>
            <Select2Field
              name="website"
              label="Website"
              options={websites}
              webChange={changeWebsite}
            />
          </div>
          <table className="styled-table mb-5">
            <thead>
              <tr>
                <th>No.</th>
                <th>Store Name</th>
                <th>Regions</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {resultData.length ? (
                resultData.map((res, index) => {
                  let visible = index % 2 == 0 ? "active-row" : "";
                  let storeName = res.store_name
                    ? `${res.store_location} - ${res.store_name}`
                    : res.store_location;
                  return (
                    <tr key={res.id} className={visible}>
                      <td>{index + 1}</td>
                      <td>{storeName}</td>
                      <td>{res.regions ? res.regions : "N/A"}</td>
                      <td>
                        <Link
                          className="text-bg-primary"
                          to={`/edit-store/${res.id}`}
                        >
                          Edit
                        </Link>{" "}
                        <button
                          href=""
                          type="button"
                          className="text-bg-danger"
                          onClick={() => alertDelete(res.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="14" className="text-center text-capitalize">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <img
          src="https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1692705802/loading_hosqnr.gif"
          alt="loading"
        />
      </div>
    </>
  );
}
