import React, { useLayoutEffect, useState } from "react";
import Banner from "./Includes/Banner";
import Navbar from "./Includes/Navbar";
import "./EmployeeTable.css";
import RsaService from "./Services/rsa.service";
import WebsitesService from "./Services/websites.service";
import StoreService from "./Services/store.service";
import AlertInfo from "./Includes/AlertInfo";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Select2Field from "./Includes/Select2Field";

import { useFormik } from "formik";
import { useEffect } from "react";

export default function EmployeeTable() {
  const [loading, setLoading] = useState(false);
  const [websites, setWebsites] = useState([]);
  const [store, setStore] = useState([]);
  const [resultData, setResultData] = useState([]);

  let cmpName = "List Employee";

  const getDataByWeb = async () => {
    // console.log(selectedWebsite);
    setLoading(true);

    try {
      const response = await RsaService.getRsaListByWebsite(values);

      let resultData;
      resultData = response.data.response;
      setResultData(resultData);
      setLoading(false);
    } catch (err) {
      setResultData([]);
      setLoading(false);
    }
  };

  const changeWebsite = (e) => {
    let value = e;
    setStore([]);
    setResultData([]);
    setFieldValue("fieldtype", "");
    setFieldValue("search", "");
    if (value !== "") {
      getDataByWeb();
      getStores(value);
      localStorage.setItem("site", value);
    }
  };

  const changeStore = (e) => {
    let value = e;
    if (value !== "") {
      getData();
    } else {
      getDataByWeb();
    }
  };

  const getStores = async (value) => {
    setLoading(true);
    try {
      const response = await StoreService.getStoreList(value);
      const results = [];
      const res = response.data.response;
      res.map((res) => {
        return results.push({
          url: res.id,
          name: res.store_name
            ? `${res.store_location} - ${res.store_name}`
            : res.store_location,
        });
      });
      setStore([...results]);
      setLoading(false);
    } catch (err) {
      if (err?.response?.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const getWebsites = async () => {
    setLoading(true);
    try {
      const response = await WebsitesService.getWebsitesList();
      setWebsites(response.data.response);
      setLoading(false);
    } catch (err) {
      if (err?.response?.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await RsaService.getRsaList(
        values.fieldtype,
        values.website,
        values.search ? values.search : ""
      );

      let resultData;
      resultData = response.data.response;
      setResultData(resultData);
      setLoading(false);
    } catch (err) {
      setResultData([]);
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    getWebsites();
    // getData();
  }, []);

  const alertDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are deleting RSA!",
      icon: "warning",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#DD6B55",
      showCancelButton: true,
      focusConfirm: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRsa(id, values.website);
      }
    });
  };

  const deleteRsa = async (id, url) => {
    try {
      setLoading(true);
      await RsaService.deleteRsa(id, url);
      if (values.fieldtype) {
        getData(values.fieldtype, values.website);
      } else {
        getDataByWeb();
      }

      setLoading(false);
      Swal.fire({
        title: "Success",
        text: "RSA has been deleted successfully",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#28a745",
      });
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "Error in deleting RSA",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#28a745",
      });
    }
  };
  let searchTimer;
  const initialValues = {
    website: "",
    fieldtype: "",
    search: "",
  };
  const { values, setFieldValue, handleChange } = useFormik({
    initialValues: initialValues,
    validationSchema: "",
    onSubmit: async (values) => {},
  });

  useEffect(() => {
    changeWebsite(values.website);
  }, [values.website]);

  useEffect(() => {
    if (values.website !== "") {
      changeStore(values.fieldtype);
    }
  }, [values.fieldtype]);
  useEffect(() => {
    if (values.website !== "") {
      clearTimeout(searchTimer);
      searchTimer = setTimeout(async () => {
        if (values.fieldtype !== "") {
          getData();
        } else {
          getDataByWeb();
        }
      }, 1000);
    }
  }, [values.search]);

  return (
    <>
      <Navbar />
      <Banner cmpName={cmpName} />
      <div className="container">
        <AlertInfo />
        <div className="mt-3 row report-get-form">
          <div className="col-lg-3">
            <label className="title">Website</label>

            <Select2Field
              name="website"
              label="Website"
              options={websites}
              webChange={handleChange}
            />
          </div>

          <div className="col-lg-3">
            <label className="title">Store</label>

            <Select2Field
              name="fieldtype"
              label="Store"
              options={store}
              webChange={handleChange}
            />
          </div>
          <div className="col-lg-3">
            <label className="title">Employee ID #</label>
            <input
              type="text"
              className="form-control"
              name="search"
              placeholder="Employee ID #"
              value={values.search || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <table className="styled-table mb-5 w-100">
          <thead>
            <tr>
              <th>No.</th>
              <th>Store</th>
              <th>RSA Full Name</th>
              <th>Employee ID Number</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {resultData.length ? (
              resultData.map((res, index) => {
                let visible = index % 2 == 0 ? "active-row" : "";
                return (
                  <tr key={res.id} className={visible}>
                    <td>{index + 1}</td>
                    <td>{res.store_location}</td>

                    <td>{`${res.fname} ${res.lname}`}</td>
                    <td>{res.employee_id}</td>
                    <td>{res.email ? res.email : "N/A"}</td>
                    <td>
                      <Link
                        className="text-bg-primary"
                        to={`/edit-rsa/${res.id}`}
                      >
                        Edit
                      </Link>{" "}
                      <button
                        href=""
                        type="button"
                        className="text-bg-danger"
                        onClick={() => alertDelete(res.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <img
          src="https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1692705802/loading_hosqnr.gif"
          alt="loading"
        />
      </div>
    </>
  );
}
