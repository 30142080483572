import React, { useState, useEffect } from "react";
import { rsaSchema } from "../schema";
import { useFormik } from "formik";
import WebsitesService from "./Services/websites.service";
import StoreService from "./Services/store.service";
import RsaService from "./Services/rsa.service";
import { toast, ToastContainer } from "react-toastify";
import Navbar from "./Includes/Navbar";
import Select2Field from "./Includes/Select2Field";

function Rsa() {
  const [loading, setLoading] = useState(false);
  const [empErr, setEmpErr] = useState("");
  const [rsaErr, setRsaErr] = useState("");
  const [stores, setStores] = useState([]);
  const [websites, setWebsites] = useState([]);
  const [selectedUrl, setSelectedUrl] = useState("");

  useEffect(() => {
    const getWebsitesList = async () => {
      const { data } = await WebsitesService.getWebsitesList();
      const { response: res } = data;
      console.log(data);
      const results = [];
      res.map((value) => {
        results.push({
          name: value.name,
          url: value.url,
          value: value.id,
        });
      });
      setWebsites([...results]);
    };
    getWebsitesList();
  }, []);

  const changeSite = (e) => {
    let site = e;
    setStores([]);
    setSelectedUrl(site);
    const getStoresList = async () => {
      const { data } = await StoreService.getStoreList(site);
      const { response: res } = data;

      const results = [];
      res.map((value) => {
        results.push({
          name: value.store_name
            ? `${value.store_location} - ${value.store_name}`
            : value.store_location,
          url: value.id,
        });
      });

      setStores([...results]);
    };
    if (site !== "") {
      getStoresList();
    }
    setFieldValue("store_location", "");
  };

  const submitData = async (values, action) => {
    setLoading(true);
    setEmpErr("");
    setRsaErr("");
    try {
      const { rsa_name } = values;
      let splitName = rsa_name.split(/\s+/);
      const fname = splitName.shift();
      const lname = splitName.join(" ");
      values.fname = fname;
      values.lname = lname;
      const { data } = await RsaService.addRsa(values, selectedUrl);
      setLoading(false);
      action.resetForm();
      toast.success("Record inserted", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (err) {
      if (err?.response?.status === 422) {
        if (err?.response?.data?.fname || err?.response?.data?.lname) {
          setRsaErr("RSA Full Name is required!");
        } else if (err?.response?.data?.employee_id) {
          setEmpErr(err?.response?.data?.employee_id[0]);
        }
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Something went wrong, try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      website: "",
      email: "",
      store_location: "",
      rsa_name: "",
      employee_id: "",
    },
    validationSchema: rsaSchema,
    onSubmit: (values, action) => {
      submitData(values, action);
    },
  });

  useEffect(() => {
    changeSite(values.website);
  }, [values.website]);

  return (
    <>
      <Navbar />
      <div className="main-w3ls">
        <div className="left-content">
          <ToastContainer />
          <div className="overlay-left"></div>
          <div className="w3ls-content">
            <h1>
              <span className="fa fa-magic"></span>Need further assistance?
            </h1>

            <h2>Email Elite Rewards.</h2>
            <a href="mailto:ashley@eliterewards.biz" className="button-w3ls">
              {/* <span className="fa fa-long-arrow-right"></span> */}
              <span className="fa fa-envelope" aria-hidden="true"></span>
            </a>
          </div>
        </div>

        <div className="right-form-agile">
          <div className="sub-main-w3">
            <h3>Update Request</h3>
            <h5>Fill out our simple form</h5>
            <p>to add or remove an RSA from your store dropdown.</p>
            <form action="" onSubmit={handleSubmit}>
              <div className="form-style-agile">
                <label>Websites</label>
                <div className="pom-agile">
                  <span className="fa fa-globe"></span>

                  <Select2Field
                    name="website"
                    label="Website"
                    options={websites}
                    webChange={handleChange}
                  />
                </div>
                {errors.website && touched.website ? (
                  <p className="help is-danger">{errors.website}</p>
                ) : null}
              </div>

              <div className="form-style-agile">
                <label>Store Location</label>
                <div className="pom-agile">
                  <span className="fa fa-map-marker"></span>

                  <Select2Field
                    name="store_location"
                    label="Store Location"
                    options={stores}
                    webChange={handleChange}
                  />
                </div>
                {errors.store_location && touched.store_location ? (
                  <p className="help is-danger">{errors.store_location}</p>
                ) : null}
              </div>
              <div className="form-style-agile">
                <label>Email</label>
                <div className="pom-agile">
                  <span className="fa fa-envelope-open"></span>
                  <input
                    placeholder="Email"
                    name="email"
                    type="email"
                    value={values.email || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required=""
                  />
                </div>
                {errors.email && touched.email ? (
                  <p className="help is-danger">{errors.email}</p>
                ) : null}
              </div>

              <div className="form-style-agile">
                <label>RSA Full Name</label>
                <div className="pom-agile">
                  <span className="fa fa-user"></span>
                  <input
                    placeholder="RSA Full Name"
                    name="rsa_name"
                    type="text"
                    value={values.rsa_name || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required=""
                  />
                </div>
                {errors.rsa_name && touched.rsa_name ? (
                  <p className="help is-danger">{errors.rsa_name}</p>
                ) : null}
                {rsaErr && rsaErr}
              </div>
              <div className="form-style-agile">
                <label>Employee ID Number</label>
                <div className="pom-agile">
                  <span className="fa fa-id-badge"></span>
                  <input
                    placeholder="Employee ID Number"
                    name="employee_id"
                    type="number"
                    required=""
                    value={values.employee_id || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.employee_id && touched.employee_id ? (
                  <p className="help is-danger">{errors.employee_id}</p>
                ) : null}
                {empErr && empErr}
              </div>

              {/* <div className="sub-agile">
              <input type="checkbox" id="brand1" value="" />
              <label htmlFor="brand1">
                <span></span>I Accept to the Terms & Conditions
              </label>
            </div> */}
              <input type="submit" value="Submit" />
            </form>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <img
          src="https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1692705802/loading_hosqnr.gif"
          alt="loading"
        />
      </div>
    </>
  );
}

export default Rsa;
