import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { rsaSchema } from "../schema";
import { useFormik } from "formik";
import WebsitesService from "./Services/websites.service";
import StoreService from "./Services/store.service";
import RsaService from "./Services/rsa.service";
import { toast, ToastContainer } from "react-toastify";
import Navbar from "./Includes/Navbar";

import { useNavigate, useParams } from "react-router-dom";
import $ from "jquery";
function EditRsa() {
  const [info, setInfo] = useState({
    website: "",
    location: "",
    email: "",
    rsa: "",
    employee_id: "",
  });
  const [loading, setLoading] = useState(false);
  const [empErr, setEmpErr] = useState("");
  const [rsaErr, setRsaErr] = useState("");

  const [stores, setStores] = useState([]);
  const [websites, setWebsites] = useState([]);

  const { id } = useParams();
  const selWeb = localStorage.getItem("site");

  const getInfoById = async (id, selWeb) => {
    const { data } = await RsaService.getRsaById(id, selWeb);
    const { response: res } = data;
    console.log("data=" + JSON.stringify(res));
    info.website = res.site;
    info.location = res.store_location;
    info.email = res.email ? res.email : "";
    info.rsa = res.fname + " " + res.lname;
    info.employee_id = res.employee_id;
    setValues({
      website: res.site,
      store_location: res.store_location,
      email: res.email ? res.email : "",
      rsa_name: res.fname + " " + res.lname,
      employee_id: res.employee_id,
    });
  };

  const getStoresList = async (url) => {
    const { data } = await StoreService.getStoreList(url);
    const { response: res } = data;
    const results = [];
    res.map((value) => {
      results.push({
        name: value.store_name
          ? `${value.store_location} - ${value.store_name}`
          : value.store_location,
        url: value.id,
      });
    });

    setStores([...results]);
  };

  useLayoutEffect(() => {
    getInfoById(id, selWeb);
    getStoresList(selWeb);
  }, []);

  useEffect(() => {
    const getWebsitesList = async () => {
      const { data } = await WebsitesService.getWebsitesList();
      const { response: res } = data;
      console.log(data);
      const results = [];
      res.map((value) => {
        results.push({
          key: value.name,
          url: value.url,
          value: value.id,
        });
      });
      setWebsites([{ key: "Please Select", value: "" }, ...results]);
    };
    getWebsitesList();
  }, []);

  // const changeSite = (e) => {
  //   let site = e.target.value;
  //   setStores([]);
  //   setSelectedUrl(site);
  //   const getStoresList = async () => {
  //     const { data } = await StoreService.getStoreList(site);
  //     const { response: res } = data;
  //     console.log(data);
  //     setStores(res);
  //   };
  //   if (site !== "") {
  //     getStoresList();
  //   }
  // };
  const navigate = useNavigate();

  const submitData = async (values) => {
    setLoading(true);
    setEmpErr("");
    setRsaErr("");
    try {
      const { rsa_name } = values;
      let splitName = rsa_name.split(/\s+/);
      const fname = splitName.shift();
      const lname = splitName.join(" ");
      values.fname = fname;
      values.lname = lname;
      const { data } = await RsaService.updateRsa(id, values, selWeb);
      setLoading(false);
      toast.success("Record updated", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => {
        navigate("/list-employee");
      }, 1000);
    } catch (err) {
      if (err?.response?.status === 422) {
        if (err?.response?.data?.fname || err?.response?.data?.lname) {
          setRsaErr("RSA Full Name is required!");
        } else if (err?.response?.data?.employee_id) {
          setEmpErr(err?.response?.data?.employee_id[0]);
        }
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Something went wrong, try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      website: "",
      email: "",
      store_location: "",
      rsa_name: "",
      employee_id: "",
    },
    validationSchema: rsaSchema,
    onSubmit: (values, action) => {
      submitData(values);
    },
  });

  const Select2Field = ({ name, label, options, webChange, value }) => {
    const selectRef = useRef();

    useLayoutEffect(() => {
      const $select = $(selectRef.current);

      $select.select2();

      $select.on("change", (event) => {
        webChange(event);
      });

      return () => {
        $select.select2("destroy");
        $select.off("change");
      };
    }, []);

    return (
      <>
        <select
          ref={selectRef}
          name={name}
          className="form-select register-select2"
          id={name}
          value={value}
        >
          <option value="">Select {label}</option>
          {options.map((opt) => (
            <option key={opt.url} value={opt.url}>
              {opt.name}
            </option>
          ))}
        </select>
      </>
    );
  };

  return (
    <>
      <Navbar />
      <div className="main-w3ls">
        <div className="left-content">
          <ToastContainer />
          <div className="overlay-left"></div>
          <div className="w3ls-content">
            <h1>
              <span className="fa fa-magic"></span>Need further assistance?
            </h1>

            <h2>Email Elite Rewards.</h2>
            <a href="mailto:ashley@eliterewards.biz" className="button-w3ls">
              {/* <span className="fa fa-long-arrow-right"></span> */}
              <span className="fa fa-envelope" aria-hidden="true"></span>
            </a>
          </div>
        </div>

        <div className="right-form-agile">
          <div className="sub-main-w3">
            <h3>Update Request</h3>
            <h5>Fill out our simple form</h5>
            <p>to add or remove an RSA from your store dropdown.</p>
            <form action="" onSubmit={handleSubmit}>
              <div className="form-style-agile">
                <label>Websites</label>
                <div className="pom-agile">
                  <span className="fa fa-globe"></span>
                  <select
                    onChange={(e) => {
                      // changeSite(e);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    name="website"
                    required=""
                    value={values.website || ""}
                    disabled
                    style={{ filter: "opacity(0.6)" }}
                  >
                    {websites.map((res) => {
                      return (
                        <option key={res.value} value={res.url}>
                          {res.key}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {errors.website && touched.website ? (
                  <p className="help is-danger">{errors.website}</p>
                ) : null}
              </div>

              <div className="form-style-agile">
                <label>Store Location</label>
                <div className="pom-agile">
                  <span className="fa fa-map-marker"></span>

                  <Select2Field
                    name="store_location"
                    label="Store Location"
                    options={stores}
                    webChange={handleChange}
                    value={values.store_location || ""}
                  />
                </div>
                {errors.store_location && touched.store_location ? (
                  <p className="help is-danger">{errors.store_location}</p>
                ) : null}
              </div>
              <div className="form-style-agile">
                <label>Email</label>
                <div className="pom-agile">
                  <span className="fa fa-envelope-open"></span>
                  <input
                    placeholder="Email"
                    name="email"
                    type="email"
                    value={values.email || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required=""
                  />
                </div>
                {errors.email && touched.email ? (
                  <p className="help is-danger">{errors.email}</p>
                ) : null}
              </div>

              <div className="form-style-agile">
                <label>RSA Full Name</label>
                <div className="pom-agile">
                  <span className="fa fa-user"></span>
                  <input
                    placeholder="RSA Full Name"
                    name="rsa_name"
                    type="text"
                    value={values.rsa_name || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required=""
                  />
                </div>
                {errors.rsa_name && touched.rsa_name ? (
                  <p className="help is-danger">{errors.rsa_name}</p>
                ) : null}
                {rsaErr && rsaErr}
              </div>
              <div className="form-style-agile">
                <label>Employee ID Number</label>
                <div className="pom-agile">
                  <span className="fa fa-id-badge"></span>
                  <input
                    placeholder="Employee ID Number"
                    name="employee_id"
                    type="number"
                    required=""
                    value={values.employee_id || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.employee_id && touched.employee_id ? (
                  <p className="help is-danger">{errors.employee_id}</p>
                ) : null}
                {empErr && empErr}
              </div>

              {/* <div className="sub-agile">
              <input type="checkbox" id="brand1" value="" />
              <label htmlFor="brand1">
                <span></span>I Accept to the Terms & Conditions
              </label>
            </div> */}
              <input type="submit" value="Update" />
            </form>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <img
          src="https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1692705802/loading_hosqnr.gif"
          alt="loading"
        />
      </div>
    </>
  );
}

export default EditRsa;
