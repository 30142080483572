import React, { useLayoutEffect, useState } from "react";
import Banner from "./Includes/Banner";
import Navbar from "./Includes/Navbar";
import "./EmployeeTable.css";
import RsaService from "./Services/rsa.service";
import WebsitesService from "./Services/websites.service";

import AlertInfo from "./Includes/AlertInfo";

import { useFormik } from "formik";
import { reports } from "../schema";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import Select2Field from "./Includes/Select2Field";

export default function ReportsTable() {
  const [loading, setLoading] = useState(false);
  const [websiteSelected, setWebsiteSelected] = useState("");

  const [resultData, setResultData] = useState([]);
  const [limit, setLimit] = useState("25");
  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");

  let cmpName = "Registration Reports";

  // const getWebsites = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await WebsitesService.getWebsitesListForReports();
  //     setWebsites(response.data.response);
  //     setLoading(false);
  //   } catch (err) {
  //     if (err?.response?.status === 404) {
  //       setLoading(false);
  //     } else {
  //       setLoading(false);
  //     }
  //   }
  // };

  // useLayoutEffect(() => {
  //   getWebsites();
  // }, []);

  const getReportsByWeb = async (values, limit, page) => {
    setLimit(limit);
    setCurrentPage(page);
    setLoading(true);
    try {
      const response = await RsaService.getWebsiteReports(values, limit, page);

      let resultData;
      resultData = response.data.data;
      setResultData(resultData);

      setTotalResults(response.data.total_records);
      setTotalPages(response.data.total_pages);
      setWebsiteSelected(values.website);
      setLoading(false);
    } catch (err) {
      setResultData([]);
      setTotalResults("0");
      setTotalPages("1");
      setLoading(false);
    }
  };
  const today = new Date().toISOString().split("T")[0];

  const initialValues = {
    website: "",
    start: today,
    end: today,
  };
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: reports,
    onSubmit: async (values) => {
      if (Date.parse(values.start) <= Date.parse(values.end)) {
        getReportsByWeb(values, limit, "1");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "End date must be greater than start date!",
        });
      }
    },
  });

  const Table = ({ data }) => {
    return (
      <>
        <table className="styled-table mb-3 w-100">
          <thead>
            <tr>
              <th>No.</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>

              <th>
                {websiteSelected === "raymourresttest.com"
                  ? "GERS Initials"
                  : "Employee ID"}
              </th>
              <th>
                {websiteSelected === "raymourresttest.com"
                  ? "Store Code & Location"
                  : "Store Location"}
              </th>
              {websiteSelected !== "raymourresttest.com" ? (
                <th>Location Key</th>
              ) : null}
              {websiteSelected === "raymourresttest.com" ? (
                <th>Phone Number</th>
              ) : null}
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {data.length ? (
              data.map((res, index) => {
                return (
                  <tr key={res.id}>
                    <td>
                      {currentPage === 1
                        ? index + 1
                        : (currentPage - 1) * limit + index + 1}
                    </td>

                    <td>{res.fname}</td>
                    <td>{res.lname}</td>
                    <td>{res.email}</td>

                    <td>{res.employee_id}</td>
                    <td>{res.store_location}</td>
                    {websiteSelected !== "raymourresttest.com" ? (
                      <td>{res.location_key}</td>
                    ) : null}
                    {websiteSelected === "raymourresttest.com" ? (
                      <td>{res.phone}</td>
                    ) : null}
                    <td>{res.date}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="7" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > parseInt(limit) && totalPages > 1 ? (
          <Pagination
            activePage={parseInt(currentPage)}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getReportsByWeb(values, limit, e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const exportToExcel = async () => {
    setLoading(true);
    try {
      const response = await RsaService.getDataForExport(values);
      let resultData;
      resultData = response.data.data;

      const worksheet = XLSX.utils.json_to_sheet(resultData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, `${response.data.fileName}`);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No Record Found",
      });
    }
  };

  return (
    <>
      <Navbar />
      <Banner cmpName={cmpName} />
      <div className="container">
        <AlertInfo />
        <form className="row report-get-form" onSubmit={handleSubmit}>
          <div className="col-lg-3 pt-3">
            <label className="title">Website</label>
            {/* <select
              className="form-select"
              name="website"
              value={values.website || ""}
              onChange={(e) => {
                handleChange(e);
              }}
            >
              <option value="">Select Website</option>
              <option value="raymourresttest.com">Raymour Rest Test</option>
            </select> */}
            <Select2Field
              name="website"
              label="Website"
              options={[
                {
                  url: "raymourresttest.com",
                  name: "Raymour Rest Test",
                },
              ]}
              webChange={handleChange}
            />
            {errors.website && touched.website ? (
              <p className="text-danger">{errors.website}</p>
            ) : null}
          </div>
          <div className="col-lg-3 pt-3">
            <label className="title">Start Date</label>
            <input
              className="form-control"
              name="start"
              value={values.start || ""}
              type="date"
              onChange={handleChange}
              max={today}
            />

            {errors.start && touched.start ? (
              <p className="text-danger">{errors.start}</p>
            ) : null}
          </div>
          <div className="col-lg-3 pt-3">
            <label className="title">End Date</label>
            <input
              className="form-control"
              name="end"
              value={values.end || ""}
              type="date"
              onChange={handleChange}
              max={today}
            />

            {errors.end && touched.end ? (
              <p className="text-danger">{errors.end}</p>
            ) : null}
          </div>
          <div className="col-lg-3 pt-3">
            <input type="submit" value="Search" className="btn-get" />
            {values.website !== "" &&
            values.start !== "" &&
            values.end !== "" ? (
              Date.parse(values.start) <= Date.parse(values.end) ? (
                // <a
                //   className="btn-get export"
                //   href={
                //     process.env.REACT_APP_API_Link +
                //     "/register/export-register-by-range/" +
                //     values.website +
                //     "/" +
                //     values.start +
                //     "/" +
                //     values.end
                //   }
                // >
                //   Export
                // </a>
                <button
                  className="btn-get export"
                  type="button"
                  onClick={exportToExcel}
                >
                  Export
                </button>
              ) : null
            ) : null}
          </div>
        </form>

        <Table data={resultData} />
        {/* <table className="styled-table mb-5 w-100">
          <thead>
            <tr>
              <th>No.</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Date</th>
              <th>Employee ID</th>
              <th>Store Location</th>
            </tr>
          </thead>
          <tbody>
            {resultData.length ? (
              resultData.map((res, index) => {
                let visible = index % 2 == 0 ? "active-row" : "";
                return (
                  <tr key={res.id} className={visible}>
                    <td>{index + 1}</td>

                    <td>{res.fname}</td>
                    <td>{res.lname}</td>
                    <td>{res.email}</td>
                    <td>{res.date}</td>
                    <td>{res.employee_id}</td>
                    <td>{res.store_location}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > parseInt(limit) && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getReportsByWeb(values, limit, e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )} */}
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <img
          src="https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1692705802/loading_hosqnr.gif"
          alt="loading"
        />
      </div>
    </>
  );
}
